    /* You can add global styles to this file, and also import other style files */

    $primary-color: #012F60;
    $yellow: #EFB200;
    $white: #fff;
    $black: #000;

    html,
    body {
        height: 100%;
    }

    body {
        margin: 0;
        font-family: Roboto, "Helvetica Neue", sans-serif;
    }

    .mat-sidenav-content,
    body,
    .mat-drawer {
        background-color: #f3f6fb;
    }

    #f3f6fb a {
        text-decoration: none;
    }

    .mat-sidenav {
        border: none;
    }

    .main {
        width: 100%;
        margin-top: 1rem;
    }

    .main-component {
        position: relative;
        padding: 0 24px;
        margin-top: 1rem;
    }

    .mat-flat-button.mat-primary,
    .mat-raised-button.mat-primary,
    .mat-fab.mat-primary,
    .mat-mini-fab.mat-primary {
        background-color: $primary-color;
        height: 40px;
        // border-radius: 10px;
    }

    .mat-form-field-underline {
        display: none;
    }

    .mat-button.mat-primary,
    .mat-icon-button.mat-primary,
    .mat-stroked-button.mat-primary {
        color: $primary-color;
        font-weight: 400;

        mat-icon {
            font-size: 20px;
        }
    }

    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
        color: $primary-color;
    }

    .mat-form-field.mat-focused .mat-form-field-label {
        color: $primary-color;
    }

    .custom-theme-btn {
        border-radius: 6px;
        height: 34px;
        min-width: 140px;
        border: none;
        transition: all ease .5s;
        font-weight: 400;
        font-size: 14px;
    }

    .btn-light {
        background-color: rgba($primary-color, 10%) !important;
        color: $primary-color;
        border: 1px solid #d0d4d8;

    }

    .btn-primary {
        background-color: $primary-color;
    }

    .custom-theme-light-btn {
        background-color: rgba($primary-color, 10%) !important;
        color: $primary-color;
    }

    ::ng-deep {
        .mat-form-field-type-mat-date-range-input .mat-form-field-infix {
            display: flex;
        }

        .tooltipClass {
            background-color: $black;
        }

        .mat-sort-header-arrow {
            color: white !important;
            margin: 0 6px 0 6px !important;
        }

        .mat-menu-panel {
            background-color: rgb(255 255 255 / 50%);
            border-radius: 12px !important;
            min-height: auto;
            backdrop-filter: blur(20px);

            .mat-menu-content {
                padding: 0 !important;

                button {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
                    color: black !important;
                    transition: all ease .5s;
                    display: flex;
                    align-items: center;

                    &:last-child {
                        border: none;
                    }

                    &:hover {
                        background-color: $white;
                    }

                    .mat-icon {
                        color: black !important;
                        font-size: 20px;
                        margin-right: .50rem;
                        padding-top: 3px;
                    }
                }
            }
        }

        th.mat-header-cell,
        td.mat-cell,
        td.mat-footer-cell {
            border-bottom-width: 0px;
            padding: 8px 1rem !important;
            white-space: nowrap;
        }

        .table td,
        .table th {
            border: 1px solid rgba(0, 0, 0, 0.07);
        }

        .mat-drawer-container,
        .mat-drawer-content {
            z-index: auto;
            overflow: auto;
        }

        .cdk-overlay-container {
            z-index: 1050;
        }

        .mat-form-field-label-wrapper {
            overflow: visible !important;
        }
    }

    .modal-content {
        border-radius: 1.50rem;
        overflow: hidden;
        z-index: 1100 !important;
    }

    .modal.fade .modal-dialog {
        // min-width: 57%;
        min-width: 80%;
        background: transparent !important;
    }

    .mat-tab-group-dynamic-height .mat-tab-body-content {
        overflow: auto !important;
    }

    @media(max-width:767px) {
        .main-component {
            padding: 0 1rem;
        }

        ::ng-deep {
            .mat-sidenav {
                background-color: transparent !important;
                box-shadow: none !important;
            }

            // .mat-drawer,
            // .mat-drawer-backdrop {
            //     z-index: 1060;
            // }
        }
    }

    .blueBtn {
        color: white;
        background-color: #062f60;
    }

    .lightblueBtn {
        color: white;
        background-color: #4d779c;

    }

    .yellowBtn {
        color: white;
        background-color: #ecac1b;
    }

    .greenBtn {
        background-color: rgb(159 218 166 / 50%);
        color: green;
    }

    .redBtn {
        background-color: rgb(245 146 146 / 50%);
        color: red;
    }

    .whiteBtn {
        color: #012F60
    }

    .pointer {
        cursor: pointer;
    }

    .multiselect-dropdown {
        .dropdown-btn {
            min-height: 53px;
            border: 1px solid rgba(0, 0, 0, .12) !important;
            color: #666666;
            padding: 17px 12px !important;

            .selected-item {
                border: 1px solid #063060 !important;
                color: white;
                background: #063060 !important;
                margin-bottom: 3px;
                font-size: 12px;
            }

            .dropdown-multiselect__caret {
                top: 8px !important;
            }
        }
    }

    .multiselect-item-checkbox input[type=checkbox]:checked+div:before {
        background: #063060 !important;
        border: 2px solid #063060 !important;
    }

    .multiselect-item-checkbox input[type=checkbox]+div:before {
        border: 2px solid #063060 !important;

    }

    .mat-typography .modal-backdrop {
        // z-index: 900 !important;
        z-index: 2 !important;
        display: none;

    }

    .custom-modal .modal-header {
        padding: 2rem 2rem;
        background-color: #012F60;
        color: #fff;
    }

    .custom-mat-form-field {
        display: inline !important;
        line-height: 1.6 !important;
        top: -5px !important;

    }

    .modal-body {
        overflow: auto !important;
    }

    .mat-select-panel-wrap {
        padding-left: 18px !important;
    }

    .mat-select-panel {
        padding-top: 8px !important;
        padding-left: 8px !important;
    }

    .modal {
        background: rgba(0, 0, 0, 0.5) !important;
    }

    .multiselect-dropdown {
        margin-bottom: 9px !important;

    }

    @media(max-width:990px) {
        .custom-modal .mat-tab-header {
            top: 4.5rem !important;
        }

        .modal-header {
            height: 20vh !important;
        }
    }

    .mat-chip.mat-standard-chip {
        background-color: #063060 !important;
        color: white;
        font-size: 12px;

        .mat-chip-remove {
            color: white;
            opacity: 1;
        }
    }


    //    *****Flex table css*********** 
    .example-container {
        display: flex;
        flex-direction: column;

    }

    .mat-table {
        overflow: auto;
        max-height: fit-content;
    }

    .mobile-label {
        display: none;
    }

    mat-cell:first-of-type,
    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
        padding-left: 0px;
    }

    mat-cell:last-of-type,
    mat-header-cell:last-of-type,
    mat-footer-cell:last-of-type {
        padding-right: 10px;
    }

    @media(max-width: 776px) {
        .mobile-label {
            width: 80px;
            display: inline-block;
            font-weight: bold;
        }

        .mat-header-row {
            display: none !important;
        }

        .mat-row {
            flex-direction: column;
            align-items: start;
            padding: 8px 24px;
        }

        mat-cell:first-of-type,
        mat-header-cell:first-of-type,
        mat-footer-cell:first-of-type {
            padding-left: 0px;
        }

        mat-cell,
        mat-header-cell,
        mat-footer-cell {
            min-height: 30px;
        }

        mat-row::after,
        mat-header-row::after,
        mat-footer-row::after {
            content: none;
        }

        mat-cell:last-of-type,
        mat-header-cell:last-of-type,
        mat-footer-cell:last-of-type {
            padding-right: 0px;
        }
    }


    .table {
        background-color: $white;
        display: table;

        // color: #111;
        mat-header-row {
            vertical-align: middle;
            border: none !important;
            display: inline-flex;
            min-width: 100%;
            background: #062f60;

            mat-header-cell {
                vertical-align: middle;
                background-color: $primary-color;
                border-top: none;
                font-weight: 400;
                font-size: 14px;
                border: none !important;
                z-index: 1 !important;
                color: white;
                width: max-content;
                // padding: 8px;
                padding-left: 19px;

                // width: 100% !important;
                &:nth-of-type(1) {
                    border-radius: 4px 0px 0px 0px;
                }

                &:nth-last-of-type(1) {
                    border-radius: 0px 4px 0px 0px;
                }

                td {
                    font-size: 13px;
                }
            }

            border-radius: 4px 4px 0px 0px;
        }

        mat-row:nth-child(even) {
            background-color: rgba($primary-color, 0.05);
        }

        mat-row {
            transition: all ease .5s;
            display: inline-flex;
            min-width: 100%;

            &:hover {
                background-color: #7D96BC;
                // box-shadow: 0px 16px 26px rgba(0, 0, 0, 0.20);
                cursor: pointer;

                mat-cell {
                    color: $white;
                }
            }

            mat-cell {
                font-size: 13px;
            }
        }

    }

    .unhoveredTable {
        background-color: $white;
        display: table;
        width: 100%;

        // color: #111;
        mat-header-row {
            vertical-align: middle;
            border: none !important;
            display: inline-flex;
            min-width: 100%;
            background: #062f60;

            mat-header-cell {
                vertical-align: middle;
                background-color: $primary-color;
                border-top: none;
                font-weight: 400;
                font-size: 14px;
                border: none !important;
                z-index: 1 !important;
                color: white;
                width: max-content;
                // padding: 8px;
                padding-left: 15px;

                // width: 100% !important;
                &:nth-of-type(1) {
                    border-radius: 4px 0px 0px 0px;
                }

                &:nth-last-of-type(1) {
                    border-radius: 0px 4px 0px 0px;
                }

                td {
                    font-size: 13px;
                }
            }

            border-radius: 4px 4px 0px 0px;
        }

        mat-row:nth-child(even) {
            background-color: rgba($primary-color, 0.05);
        }

        mat-row {
            transition: all ease .5s;
            display: inline-flex;
            min-width: 100%;

            // &:hover {
            //     background-color: #7D96BC;
            //     // box-shadow: 0px 16px 26px rgba(0, 0, 0, 0.20);
            //     cursor: pointer;
            //     mat-cell {
            //         color: $white;
            //     }
            // }
            mat-cell {
                font-size: 13px;
            }
        }
    }

    // *****Flex table css end***********

    .searchInput {
        background: rgb(6 47 96 / 70%);
        border-radius: 20px;
        color: white;
    }

    .searchInput::placeholder {
        color: white;
        opacity: 0.5;
    }

    .searchInput:focus {
        color: black;
        background-color: #f4f5fa;
        border-color: #80bdff;
        outline: 0;
        box-shadow: none
    }

    .searchInput:focus::placeholder {
        color: black;
    }

    .mainFilterCard {
        border-radius: 14px;
        // background: #c6cbca57;
    }

    .mainFilterHeading {
        justify-content: space-between;
        ;
        display: flex;

        h3 {
            color: #012F60;
        }

        button {
            border-radius: 50%;
            height: fit-content;
            float: right;
            padding: 0;
            background: #063060;
        }
    }

    .rangeMsg {
        margin-top: -8px;
        padding-bottom: 9px;
        font-size: 11px;
    }

    .md-drppicker.shown {
        width: max-content !important;
    }

    .md-drppicker .clear svg {
        display: none !important;
    }

    .md-drppicker .ranges ul li button.active {
        background-color: #062f60 !important;
        border-radius: 6px !important;
        text-transform: capitalize !important;
        // padding: 0px 15px 0px 15px !important;
    }

    .md-drppicker td.active {
        background-color: #062f60 !important;
    }

    .md-drppicker .clear {
        margin-right: 11px !important;
        border-radius: 6px !important;
        text-transform: capitalize !important;
        padding: 0px 15px 0px 15px !important;
    }

    .md-drppicker .btn {
        background-color: #062f60 !important;
        border-radius: 6px !important;
        text-transform: capitalize !important;
        // padding: 0px 15px 0px 15px !important;
    }

    @media screen and (min-width: 991px) {
        .custom-picker-left {
            .md-drppicker.shown {
                left: auto !important;
                right: 0px !important;
            }
        }
    }

    @media screen and (max-width: 992px) and (min-width: 576px) {
        .custom-picker-right {
            .md-drppicker.shown {
                left: auto !important;
                right: 0px !important;
            }
        }
    }

    .date-clear {
        position: relative;
        top: -3px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.54);
    }